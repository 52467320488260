import React from "react"
import { Routes } from "../../data/routes"
import { Profiles } from "../../data/assets"
import { TutorFAQs } from "../../data/faq"
import { PrimaryButton } from "../../components/button"
import FeaturesThreeColumn from "../../components/FeaturesThreeColumn"
import Img from "../../components/img"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Testimonial from "../../components/Testimonial"

import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"

const Content = {
  pageName: "Tutors",
  header: `Become a Yup tutor and help empower students`,
  description: `Yup tutors are current and former teachers, educators, and
    graduate students with extensive tutoring experience. They help
    students around the world to get unstuck on their homework, master
    concepts, and gain greater math confidence.

    Yup tutors make a real difference for students, their families,
    and students' long-term academic success.
  `,
  headerAction: "Apply Now",
  headerLink: "https://tutor.yup.com/tutor_onboarding/tutor_onboarding",
  headerImage: "tutors-header-img.svg",

  // Benefits section
  benefitsHeader: "",
  benefitsSubheader: "",
  benefits: [
    {
      name: "Work when you want",
      description: `Yup tutors earn money on their own schedule`,
      icon: "icon-money.svg",
    },
    {
      name: "Grow your skills",
      description: `Yup provides tutors with resources for learning
        education best practices`,
      icon: "icon-growth.svg",
    },
    {
      name: "Make a real difference",
      description: `Yup tutors help students learn and don't just give them the answers`,
      icon: "icon-student-globe.svg",
    },
  ],

  // Apply now CTA
  applyNowCTAHeader: "Apply to be a Yup tutor to earn on your schedule",
  applyNowCTAText: "The application process includes:",
  applyNowSteps: [
    "Written application",
    "Math proficiency exam",
    "Teaching exam",
    "Demonstration sessions, conduct live tutoring sessions",
  ],
  applyNowAction: "Apply Now",
  applyNowLink: "https://tutor.yup.com/tutor_onboarding/tutor_onboarding",
  applyNowImage: "tutors-cta-img.svg",
  applyNowImageAltText: "Line drawing of woman using a laptop",

  // How we tutor section
  howWeTutorHeader: "How we tutor",
  howWeTutorSubheader: `Yup's pedagogy, or teaching methodology, was co-developed with thought
    leaders at top universities, and it sets us apart from other tutoring providers.`,
  howWeTutorPrinciples: [
    {
      name: "Growth mindset",
      description: `Yup tutors promote a positive and supportive learning environment
        so that students can do their best`,
      icon: "icon-excellence.svg",
    },
    {
      name: "Socratic method",
      description: `Yup tutors ask guiding questions so that students take an active role
        in reaching solutions`,
      icon: "icon-socratic-method.svg",
    },
    {
      name: "Learning, not cheating",
      description: `Yup tutors don't just give away answers. They help students
        understand concepts`,
      icon: "icon-cap.svg",
    },
  ],

  // Testimonial section
  testimonial: `I've been with Yup for many years now and I thoroughly enjoy
    working here. Yup brings people who share a passion for making a difference
    in education together, and the team diligently provides nothing but the
    best to Yup's users. Yup's goal of making education more accessible is sure to
    bring about a change in learning—and I'm excited to be a part of it!`,
  testimonialProfile: Profiles.madhu,
  testimonialName: "Madhu R.",
  testimonialJob: "Yup Tutor",

  // FAQ section
  faqHeader: "Frequently Asked Questions",
  faqs: TutorFAQs,

  // Help section
  helpTitle: "Still have questions?",
  helpText: `Please visit our <a target="_blank" class="text-secondary mx-2" href="https://helpcenter.yup.com/hc/en-us/sections/9572549028499-For-Tutor-Applicants">Help Center</a>`,
}

function Header() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-8f2857c2b9324bb3fe8b1c455438ae07
  return (
    <div>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:py-16 lg:px-8 lg:py-20">
        <div className="flex flex-col-reverse md:flex-row justify-between">
          <div className="p-4">
            <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">{Content.header}</span>
            </h1>
            {Content.description.split(/\n\n/g).map(description => (
              <p key={description} className="mt-3 text-lg">
                {description}
              </p>
            ))}
            <div className="mt-8 flex">
              <PrimaryButton
                text={Content.headerAction}
                href={Content.headerLink}
              />
            </div>
          </div>
          <Img src={Content.headerImage} alt={Content.description} />
        </div>
      </div>
    </div>
  )
}

function CTASection() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-8f2857c2b9324bb3fe8b1c455438ae07
  return (
    <div>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:py-16 lg:px-8 lg:py-20">
        <div className="flex flex-col-reverse md:flex-row justify-between">
          <div className="p-4">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              {Content.applyNowCTAHeader}
            </h2>
            <p className="mt-3 text-lg">{Content.applyNowCTAText}</p>
            <ol className="mt-3 text-lg list-decimal ml-8">
              {Content.applyNowSteps.map(step => (
                <li key={step}>{step}</li>
              ))}
            </ol>
            <div className="mt-8 flex">
              <PrimaryButton
                text={Content.applyNowAction}
                href={Content.applyNowLink}
              />
            </div>
          </div>
          <Img src={Content.applyNowImage} alt={Content.applyNowImageAltText} />
        </div>
      </div>
    </div>
  )
}

function FAQSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/faq-sections#component-8699d80b13ef524eb573e54b4d4b89d1
  return (
    <div className="pb-16 pt-8">
      <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
        <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
          {Content.faqHeader}
        </h2>
        <dl className="mt-6 space-y-6 divide-y divide-gray-200">
          {Content.faqs.map(faq => (
            <Disclosure as="div" key={faq.question} className="pt-6">
              {({ open }) => (
                <>
                  <dt className="text-lg">
                    <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                      <span className="font-medium text-gray-900">
                        {faq.question}
                      </span>
                      <span className="ml-6 h-7 flex items-center">
                        <ChevronDownIcon
                          className={`h-6 w-6 transform ${
                            open ? "-rotate-180" : "rotate-0"
                          }`}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    <p className="text-base text-gray-500 whitespace-pre-wrap">
                      {faq.answer}
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  )
}

export function HelpCenter() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/heroes#component-6364cb4174b8dfdfbd7fa71ac72ab862
  return (
    <div className="relative">
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="relative px-4 py-4">
          <h2 className="text-xl font-extrabold tracking-tight text-center">
            <span className="block">{Content.helpTitle}</span>
          </h2>
          <div
            className="flex flex-col sm:flex-row justify-center"
            dangerouslySetInnerHTML={{ __html: Content.helpText }}
          />
        </div>
      </div>
    </div>
  )
}

const Tutors = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.header}
        route={Routes.tutors}
      />
      <Header />
      <FeaturesThreeColumn
        features={Content.benefits}
        title={Content.benefitsHeader}
        subtitle={Content.benefitsSubheader}
      />
      <CTASection />
      <FeaturesThreeColumn
        title={Content.howWeTutorHeader}
        subtitle={Content.howWeTutorSubheader}
        features={Content.howWeTutorPrinciples}
      />
      <Testimonial
        image={Content.testimonialProfile}
        testimonial={Content.testimonial}
        name={Content.testimonialName}
        jobs={[Content.testimonialJob]}
      />
      <FAQSection />
      <HelpCenter />
    </Layout>
  )
}

export default Tutors
